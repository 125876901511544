.Header {
    background-color: var(--header-bg-color);
    color: #ffffff;

    // Link
    .Link {
        color: #fff; 
        text-shadow: 1px 1px 2px #ffffff3c; 
        text-decoration: none;
        cursor: pointer;

        // Hover State
        &:hover {
            text-decoration: underline;
        }
    }
}