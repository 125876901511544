.Accounts {

    // Chips
    .Chips {
        gap: 1rem;
        position: sticky;
        top: 62px;
        background-color: var(--primary-bg-color);

        // Item
        .Item {
            cursor: pointer; 
            border: 1px solid #e7e7e9; 
            border-radius: 40px; 
            border-radius: 40px; 
            white-space: nowrap;

            // Hover State
            &:hover {
                border-color: #9e9ea7; 
                background-color: #e7e7e98f;
            }

            // Active
            &[data-active="true"] { 
                border-color: #9494d08f; 
                background-color: #9494d08f;
            }
        }
    }

    // AccountsCards
    .AccountsCards {
        gap: 1rem;
        
        // Card
        .Card {
            width: 300px;
            border-radius: 5px;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 3px 0 rgba(0, 0, 0, .2);
        }

        // CardTitle
        .CardTitle {
            color: rgba(0, 0, 0, .5);

            // Link
            .Link {
                color: rgba(0, 0, 0, .25);

                // Hover State
                &:hover {
                    color: rgba(0, 0, 0, .5);
                }
            }
        }

        // AccountsList
        .AccountsList {

            // Item
            .Item {


                // Link
                .Link {
                    cursor: pointer;
                    color: #3a54d1;

                    // Hover State
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}