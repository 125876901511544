.InputWrapper {

    .WrapperColumn {
        min-height: 100%;
        position: relative;

        // Input
        .Input {
            background-color: var(--primary-bg-color);
            border: 1px solid #ccc;
            color: #333333;
            padding-right: 0.25rem;
            min-height: 100%;

            // InputElement
            .InputElement {
                padding: 0.25rem;
                background-color: var(--primary-bg-color);
                border: none;
                color: #333333;
                outline: none;
                font-size: .875rem;
                width: 100%;
                flex-grow: 1;

                // Textarea
                &.Textarea {
                    resize: none;
                }

                // Hide Arrows for Number Input
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            // Hide border
            &[data-hideborder="true"] {
                border: none;
            }

            // Invalid
            &[data-invalid="true"] {
                border: 1px solid red;
            }

            // Clear
            .Clear {
                color: #ccc;

                // Inactive
                &[data-active="false"] {
                    visibility: hidden;
                    pointer-events: none;
                }

                // Hover
                &:hover {
                    color: #333;
                }
            }

            // NumberControls
            .NumberControls {

                .Arrow {
                    cursor: pointer;
                    color: #ccc;

                    &:hover {
                        color: #333;
                    }
                }
            }
        }

        // Error
        .Error {
            color: red;
            position: absolute;
            top: 100%;
            left: 0;
        }
    }
}