.Text {

    // Color primary 
    &[data-color="primary"] {
        color: var(--text-primary-color);
    }

    // Color secondary
    &[data-color="secondary"] {
        color: var(--text-secondary-color);
    }

    // Bold
    &[data-bold="true"] {
        font-weight: bold;
    }

    // Size 0 
    &[data-size="0"] {
        font-size: var(--text-size-x0);
    }

    // Size 1
    &[data-size="1"] {
        font-size: var(--text-size-x1);
    }

    // Size 2
    &[data-size="2"] {
        font-size: var(--text-size-x2);
    }

    // Size 3
    &[data-size="3"] {
        font-size: var(--text-size-x3);
    }

    // Size 4
    &[data-size="4"] {
        font-size: var(--text-size-x4);
    }

    // Size 5
    &[data-size="5"] {
        font-size: var(--text-size-x5);
    }

    // Size 6
    &[data-size="6"] {
        font-size: var(--text-size-x6);
    }

    // Size 7
    &[data-size="7"] {
        font-size: var(--text-size-x7);
    }

    // Size 8
    &[data-size="8"] {
        font-size: var(--text-size-x8);
    }

    // Size 9
    &[data-size="9"] {
        font-size: var(--text-size-x9);
    }

    // Size 10
    &[data-size="10"] {
        font-size: var(--text-size-x10);
    }

    // Size 11
    &[data-size="11"] {
        font-size: var(--text-size-x11);
    }

    // Size 12
    &[data-size="12"] {
        font-size: var(--text-size-x12);
    }
}