@import url('https://fonts.googleapis.com/css?family=Poppins');

* {
  font-family: 'Poppins', 'Open Sans', sans-serif;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow: hidden;
}