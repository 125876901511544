.Modal {

    .Window {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 5px;
        padding: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, .5);
        z-index: 2;
        max-width: 90vw;
        max-height: 75vh;
    }
}