.Options {
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    position: fixed;
    top: 0;
    min-width: 200px;
    z-index: 2;

    // Option
    .Option {
        padding: 0.25rem;

        // Hover
        &:hover {
            background-color: #f0f0f0;
            cursor: pointer;
        }
    }
}