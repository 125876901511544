.Queries {
    
    // List
    .List {
        
        // Item
        .Item {
            cursor: pointer;
            border: 1px solid #dddddd;
            border-bottom: none;
            background-color: #f2f2f2;

            // Hover State
            &:hover {
                background-color: #f5f5f5;
            }

            // Last Item
            &:last-child {
                border-bottom: 1px solid #dddddd;
            }

            // Element
            .Element {
                color: #3a54d1;
                text-decoration: none;

                // Hover State
                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }
            }
        }
    }
}