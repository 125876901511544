.Devider {
    padding: 1rem 0; 
    position: sticky; 
    top: 0; 
    background-color: #fff;
    z-index: 3;

    // Div
    > div {
        display: flex; 
        align-items: center; 
        padding: 0 1rem;
    }

    // Span
    > div span { 
        display: block; 
        padding: .125rem 2rem; 
        border-radius: 1rem; 
        text-transform: uppercase; 
        background-color: rgba(0, 0, 0, 0.08); 
        white-space: nowrap;
    }
    
    // Before
    > div::before { 
        content: ""; 
        display: block; 
        width: 100%; 
        margin-right: 1rem; 
        border-top: 1px solid rgba(0, 0, 0, .12);
    }
    
    // After
    > div::after { 
        content: ""; 
        display: block; 
        width: 100%; 
        margin-left: 1rem; 
        border-top: 1px solid rgba(0, 0, 0, .12);
    }
}