.ag-cell-wrapper {
    min-height: 100%;
    line-height: 1.5rem;
}

.ag-ltr .ag-cell {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ag-cell.ag-cell-inline-editing {
    padding-left: 0;
    padding-right: 0;
}

.ag-header-cell-text {
    font-size: 13px;
}