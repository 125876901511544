.FlexElement {
    display: flex;

    // Grow 0
    &[data-grow='0'] {
        flex-grow: 0;
    }

    // Grow 1
    &[data-grow='1'] {
        flex-grow: 1;
    }

    // Shrink 0
    &[data-shrink='0'] {
        flex-shrink: 0;
    }

    // Shrink 1
    &[data-shrink='1'] {
        flex-shrink: 1;
    }

    // Wrap - wrap
    &[data-wrap="wrap"] {
        flex-wrap: wrap;
    }

    // Wrap - nowrap
    &[data-wrap="nowrap"] {
        flex-wrap: nowrap;
    }

    // Align Items - start
    &[data-alignitems="start"] {
        align-items: flex-start;
    }

    // Align Items - center
    &[data-alignitems="center"] {
        align-items: center;
    }

    // Align Items - end
    &[data-alignitems="end"] {
        align-items: flex-end;
    }

    // Align Items - stretch
    &[data-alignitems="stretch"] {
        align-items: stretch;
    }

    // Align Content - start
    &[data-aligncontent="start"] {
        align-content: flex-start;
    }

    // Align Content - center
    &[data-aligncontent="center"] {
        align-content: center;
    }

    // Align Content - end
    &[data-aligncontent="end"] {
        align-content: flex-end;
    }

    // Align Content - stretch
    &[data-aligncontent="stretch"] {
        align-content: stretch;
    }

    // Justify Content - start
    &[data-justifycontent="start"] {
        justify-content: flex-start;
    }

    // Justify Content - center
    &[data-justifycontent="center"] {
        justify-content: center;
    }

    // Justify Content - end
    &[data-justifycontent="end"] {
        justify-content: flex-end;
    }

    // Justify Content - between
    &[data-justifycontent="between"] {
        justify-content: space-between;
    }

    // Justify Content - around
    &[data-justifycontent="around"] {
        justify-content: space-around;
    }

    // Justify Content - evenly
    &[data-justifycontent="evenly"] {
        justify-content: space-evenly;
    }

    // Align Self - start
    &[data-alignself="start"] {
        align-self: flex-start;
    }

    // Align Self - center
    &[data-alignself="center"] {
        align-self: center;
    }

    // Align Self - end
    &[data-alignself="end"] {
        align-self: flex-end;
    }

    // Align Self - stretch
    &[data-alignself="stretch"] {
        align-self: stretch;
    }

    // Justify Items - start
    &[data-justifyitems="start"] {
        justify-items: flex-start;
    }

    // Justify Items - center
    &[data-justifyitems="center"] {
        justify-items: center;
    }

    // Justify Items - end
    &[data-justifyitems="end"] {
        justify-items: flex-end;
    }

    // Justify Items - stretch
    &[data-justifyitems="stretch"] {
        justify-items: stretch;
    }
}