.DataTable {

    .EditableCell {

        // Triangle
        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: -4px;
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #1e90ff59;
            transform: rotate(45deg);
        }
    }
}