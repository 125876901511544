@use "sass:string";

.Element {

    // Text Wrap
    &[data-textwrap='false'] {
        white-space: nowrap;
    }

    // Indents
    $Indents: "0", "0.125", "0.25", "0.375", "0.5", "0.625", "0.75", "0.875", 
    "1", "1.125", "1.25", "1.375", "1.5", "1.625", "1.75", "1.875", 
    "2";

    @each $Indent in $Indents {

        // Paddings
        &[data-paddingtop='#{$Indent}'] {
            padding-top: unquote($Indent + "rem");
        }

        &[data-paddingleft='#{$Indent}'] {
            padding-left: unquote($Indent + "rem");
        }

        &[data-paddingright='#{$Indent}'] {
            padding-right: unquote($Indent + "rem");
        }

        &[data-paddingbottom='#{$Indent}'] {
            padding-bottom: unquote($Indent + "rem");
        }

        // Margins
        &[data-margintop='#{$Indent}'] {
            margin-top: unquote($Indent + "rem");
        }

        &[data-marginleft='#{$Indent}'] {
            margin-left: unquote($Indent + "rem");
        }

        &[data-marginright='#{$Indent}'] {
            margin-right: unquote($Indent + "rem");
        }

        &[data-marginbottom='#{$Indent}'] {
            margin-bottom: unquote($Indent + "rem");
        }
    }
}