.Dropdown {
    position: relative;
    width: 100%;
    min-width: 200px;
    background-color: #fff;

    // Input
    .Input {
        min-height: 100%;
        border: 1px solid #ccc;

        // Invalid
        &[data-invalid="true"] {
            border: 1px solid red;
        }

        // InputElement
        .InputElement {
            min-height: 100%;
        }
    }

    // Selected
    .Selected {
        padding: 0.25rem;
        border: 1px solid #ccc;
        cursor: pointer;

        // Invalid
        &[data-invalid="true"] {
            border: 1px solid red;
        }

        // Arrow
        .Arrow {
            color: #ccc;
        }

        // Hover
        &:hover {

            // Arrow
            .Arrow {
                color: #333;
            }
        }
    }
}