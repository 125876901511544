.Dashboard {

    .ChartsContainer {
        display: grid;
        flex-grow: 1;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-template-rows: repeat(auto-fit, 320px);
        grid-gap: 2rem;
        padding-top: 0.125rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        // Card
        .Card {
            cursor: pointer;
            display: grid;
            opacity: .75;

            &:hover {
                opacity: 1;
            }
            
            svg {
                cursor: pointer;
            }
        }
    }
}