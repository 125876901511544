.Login {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;

    .Form {
        background-color: white;
        padding: 1.25rem;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        width: 300px;

        // Title
        .Title {
            color: #777;
            font-size: 32px;
            margin: 25px 0;
            text-align: center;
        }

        // Input
        .Input {
            padding: .5rem 0;
            margin-bottom: 10px;
            border-radius: 3px;
            border: 1px solid transparent;
            text-align: center;
            width: 100%;
            font-size: 16px;
            transition: border .2s, background-color .2s;
            background-color: #ECF0F1;
            outline: none;

            // Focus State
            &:focus {
                border: 1px solid #3498DB;
            }
        }

        // Submit
        .Submit {
            border: none;
            background-color: #3498DB;
            color: #fff;
            height: 2.5rem;
            padding: 0 1rem;
            border-radius: 5px;
            cursor: pointer;
            width: 100%;

            // Hover State
            &:hover {
                background-color: #1F78B4;
            }
        }
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}