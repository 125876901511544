.Chart {
    border-radius: 5px; 
    cursor: pointer; 
    color: rgba(0, 0, 0, .5); 
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 3px 0 rgba(0, 0, 0, .2);

    // Title
    .Title {
        color: rgba(0, 0, 0, .5); 
    }
}