:root {
    /* Colors */
    --text-primary-color: #000000;
    --text-secondary-color: #ffffff;

    /* Text Sizes */
    --text-size-x0: 0.75rem;    /* 12px */
    --text-size-x1: 0.875rem;   /* 14px */
    --text-size-x2: 1rem;       /* 16px */
    --text-size-x3: 1.125rem;   /* 18px */
    --text-size-x4: 1.25rem;    /* 20px */
    --text-size-x5: 1.5rem;     /* 24px */
    --text-size-x6: 1.75rem;    /* 28px */
    --text-size-x7: 2rem;       /* 32px */
    --text-size-x8: 2.25rem;    /* 36px */
    --text-size-x9: 2.5rem;     /* 40px */
    --text-size-x10: 2.75rem;   /* 44px */
    --text-size-x11: 3rem;      /* 48px */
    --text-size-x12: 3.25rem;   /* 52px */

    /* Background Colors */
    --primary-bg-color: #ffffff;
    --secondary-bg-color: #F5F6F8;
    --header-bg-color: #3B54D0;
}