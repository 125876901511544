.PageTitle {
    text-decoration: underline;
    position: sticky; 
    top: 0; 
    z-index: 1;
    background: #fff;

    // BackBtn
    .BackBtn { 
        cursor: pointer;
    }

    // DownloadBtn
    .DownloadBtn {
        cursor: pointer;

        // Hover State
        &:hover {
            color: var(--header-bg-color);
        }
    }
}