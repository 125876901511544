.Area {
    
    // Cards
    .Cards {
        display: flex; 
        gap: 2rem; 
        flex-wrap: wrap; 
        justify-content: center; 
        padding: 1rem 5%;

        // Card 
        .Card {
            flex-basis: 300px; 
            height: 160px; 
            padding: 1rem; 
            text-decoration: none; 
            flex-shrink: 0; 
            border-radius: 5px; 
            cursor: pointer; 
            color: rgba(0, 0, 0, .5); 
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 3px 0 rgba(0, 0, 0, .2);

            // Title
            .Title {
                display: flex; 
                align-items: center; 
                font-size: 1.25rem; 
                padding-bottom: 1rem;

                // Icon
                .Icon {
                    font-size: 1.5rem;
                }

                // Name
                .Name {
                    padding-left: .5rem;
                }
            }

            // Description
            .Description {
                font-size: .875rem;
            }

            // Card Hover State
            &:hover {
                color: rgba(0, 0, 0, .75); 
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .28), 0 2px 1px -1px rgba(0, 0, 0, .24), 0 1px 3px 0 rgba(0, 0, 0, .4);
            }
        }
    }
}